import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import EditRenewalStageModal from './EditRenewalStageModal';
import NewRenewalStageModal from './NewRenewalStageModal';
import RenewalStageRowMenu from './RenewalStageRowMenu';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import DataTable from '../../../shared/components/DataTable';
import { RenewalStagesContext } from '../contexts/RenewalStagesContext';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'GREEN',
    selector: 'green_range',
    sortable: true,
  },
  {
    name: 'YELLOW',
    selector: 'yellow_range',
    sortable: true,
  },
  {
    name: 'RED',
    selector: 'red_range',
    sortable: true,
  },
  {
    name: 'RENEWALS COUNT',
    selector: 'renewals_count',
    sortable: true,
  },
  {
    cell: (renewalStage) => <RenewalStageRowMenu renewalStage={renewalStage} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

const RenewalStagesList: FunctionComponent = () => {
  const { renewalStages, isLoading } = React.useContext(RenewalStagesContext);

  const [newStageModalOpen, setNewStageModalOpen] = React.useState(false);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <EditRenewalStageModal />
      <NewRenewalStageModal isOpen={newStageModalOpen} setOpen={setNewStageModalOpen} />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Renewal Stages
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setNewStageModalOpen(true)}>
            Create New Renewal Stage +
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={renewalStages} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Card>
      <br />
    </Box>
  );
};

export default RenewalStagesList;
