import React, { FunctionComponent } from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { School } from '../model';

interface CurrentAcademicYearInformationProps {
  school: School;
  schoolLoading: boolean;
}

const CurrentAcademicYearInformation: FunctionComponent<CurrentAcademicYearInformationProps> = (props) => {
  const { school, schoolLoading } = props;

  return schoolLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Current Academic Year Details
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InformationField label="Overall Start Date" value={school?.start_date} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField label="Overall End Date" value={school?.end_date} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InformationField label="Q1 Start Date" value={school?.q1_sd} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField label="Q1 End Date" value={school?.q1_ed} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InformationField label="Q2 Start Date" value={school?.q2_sd} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField label="Q2 End Date" value={school?.q2_ed} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InformationField label="Q3 Start Date" value={school?.q3_sd} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField label="Q3 End Date" value={school?.q3_ed} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InformationField label="Q4 Start Date" value={school?.q4_sd} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField label="Q4 End Date" value={school?.q4_ed} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InformationField label="ESY Start Date" value={school?.esy_sd} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField label="ESY End Date" value={school?.esy_ed} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField label="Closure Dates" value={school?.academic_years?.[0]?.formatted_closure_dates} />
          </Grid>
          <Grid item xs={12} md={8}>
            <InformationField
              label="Inservice Dates"
              value={school?.academic_years?.[0]?.formatted_inservice_dates || 'None'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CurrentAcademicYearInformation;
