import React, { FunctionComponent } from 'react';
import { Box, Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { SessionNote } from '../model';
import { Service } from '../../services/model';
import { ServiceSubjectNotes } from '../../students/model';

const serviceColumns: IDataTableColumn<SessionNote>[] = [
  {
    name: 'Service Type',
    selector: 'service_type',
    sortable: true,
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
    sortFunction: (rowA: Service, rowB: Service) =>
      new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
    sortFunction: (rowA: Service, rowB: Service) =>
      new Date(rowA.end_date).getTime() - new Date(rowB.end_date).getTime(),
  },
  {
    name: 'Subjective Notes Count',
    selector: 'subjective_notes_count',
    sortable: true,
  },
  {
    name: 'Providers',
    selector: 'owners',
    sortable: false,
  },
];

const noteColumns: IDataTableColumn<SessionNote>[] = [
  {
    name: 'DATE',
    selector: (row) => row.appointment_date,
    sortable: true,
    sortFunction: (rowA: SessionNote, rowB: SessionNote) =>
      new Date(rowA.appointment_date).getTime() - new Date(rowB.appointment_date).getTime(),
  },
  {
    name: 'CREATED BY',
    selector: 'created_by',
    sortable: true,
  },
  {
    name: 'CONTENT',
    selector: 'all_fields',
    sortable: true,
    grow: 8,
    cell: (row: SessionNote) => (
      <div>
        <p>
          <strong>Content: </strong>
          {row.subjective_comment || row.content || 'N/A'}
        </p>
        {row.assistance && (
          <p>
            <strong>Assistance: </strong>
            {row.assistance}
          </p>
        )}
        {row.percent_string && (
          <p>
            <strong>Percent: </strong>
            {row.percent_string}
          </p>
        )}
      </div>
    ),
  },
];

interface SubjectiveSessionNotesProps {
  serviceSubjectiveNotes?: ServiceSubjectNotes[];
  title?: string;
}

const SubjectiveSessionNotes: FunctionComponent<SubjectiveSessionNotesProps> = (props) => {
  const { serviceSubjectiveNotes, title = 'Goal History' } = props;

  const ExpandedHistoryList = ({ data }) => {
    return (
      <Box my={4}>
        <DataTable
          columns={noteColumns}
          noHeader
          data={data.subjective_notes}
          striped
          highlightOnHover
          pointerOnHover
          pagination
        />
      </Box>
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          {title}
        </Typography>
        <DataTable
          columns={serviceColumns}
          noHeader
          data={serviceSubjectiveNotes}
          striped
          highlightOnHover
          pointerOnHover
          expandableRows
          expandableRowsComponent={<ExpandedHistoryList data />}
        />
      </CardContent>
    </Card>
  );
};

export default SubjectiveSessionNotes;
