import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { User } from '../model';
import UserInformation from './UserInformation';
// import UserServices from './UserServices';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface UserProfileTabProps {
  user: User;
  userLoading: boolean;
}

const UserProfileTab: React.FC<UserProfileTabProps> = (props) => {
  const { user, userLoading } = props;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <UserInformation user={user} userLoading={userLoading} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UserProfileTab;
