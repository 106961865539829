import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { School } from '../model';
import { schoolFormStore } from '../../shared/singletons';
import DeleteModal from '../../shared/components/modal/DeleteModal';

const useStyles = makeStyles((theme) => ({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
  listItemIcon: {
    minWidth: '24px',
  },
}));

interface SchoolCustomActionsProps {
  school: School;
}

const SchoolCustomActions: FunctionComponent<SchoolCustomActionsProps> = (props) => {
  let { school } = props;

  let classes = useStyles();
  let history = useHistory();

  let [isDestroyModalOpen, setDestroyModalOpen] = useState<boolean>(false);

  const handleDestroyFormSubmit = () => {
    schoolFormStore.deleteSchool(school.id);
    setDestroyModalOpen(false);
  };

  return (
    <>
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to delete this school? This action cannot be undone.
        </Typography>
      </DeleteModal>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                ESY Scheduling
              </Typography>
              <Typography>Schedule tagged ESY students.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/schools/${school.id}/esy_scheduling`)}
              >
                Go To ESY Scheduling
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {school?.discharged ? (
        <Card style={{ marginTop: 30 }}>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h2" component="h2">
                  Reactive School
                </Typography>
                <Typography>
                  Permanently discharge all students and services from a school, this cannot be undone.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/schools/${school.id}/discharge`)}
                >
                  Reactivate School
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Card style={{ marginTop: 30 }}>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h2" component="h2">
                  Discharge School
                </Typography>
                <Typography>
                  Permanently discharge all students and services from a school, this cannot be undone.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/schools/${school.id}/discharge`)}
                >
                  Discharge School
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Delete School
              </Typography>
              <Typography>Permanently delete school from the timeline site, this cannot be undone.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                disabled={school?.has_active_appointments}
                style={{ backgroundColor: school?.has_active_appointments ? '' : '#F44F64' }}
                onClick={() => setDestroyModalOpen(true)}
              >
                Delete User
              </Button>
              {school?.has_active_appointments && (
                <p style={{ color: 'red' }}>This school has active appointments and cannot be deleted.</p>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SchoolCustomActions;
