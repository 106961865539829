import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Renewal } from '../../renewals/model';
import RenewalRowMenu from './RenewalRowMenu';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'TYPE',
    selector: 'type',
    sortable: true,
  },
  {
    name: 'Service',
    selector: 'referral_service_id',
    cell: (row) => (
      <div>
        {row.referral_service_id ? (
          <a href={`/students/${row.student_id}/services/${row.referral_service_id}`}>{row.service_name}</a>
        ) : (
          <p>N/A</p>
        )}
      </div>
    ),
  },
  {
    name: 'PROVIDER',
    selector: 'owner',
    sortable: true,
  },
  {
    name: 'RENEWAL DATE',
    selector: 'formatted_renewal_date',
    sortable: true,
  },
  {
    name: 'THERAPIST INPUT',
    selector: 'therapist_input_date',
    sortable: true,
  },
  {
    name: 'IEP DUE TO HOPE',
    selector: 'iep_due_to_hope_date',
    sortable: true,
  },
  {
    name: 'INPUT APPROVAL',
    selector: 'input_approval_date',
    sortable: true,
  },
  {
    name: 'STAGE',
    selector: 'stage',
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: 'status',
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
        }}
      >
        {row.status}
      </div>
    ),
    sortable: true,
  },
  {
    cell: (renewal: Renewal) => (
      <RenewalRowMenu
        renewal={renewal}
        onDelete={() => {
          /* eslint-disable */
          window.location = window.location;
          /* eslint-enable */
        }}
      />
    ),
    name: 'ACTIONS',
    button: true,
    selector: 'actions',
  },
];

interface ManageRenewalsProps {
  renewals: Renewal[];
  appointmentLoading: boolean;
}

const ManageRenewals: FunctionComponent<ManageRenewalsProps> = (props) => {
  const { renewals, appointmentLoading } = props;

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid item xs={12}>
      <Typography variant="h2" component="h2">
        Manage Renewals
      </Typography>
      <DataTable columns={columns} noHeader data={renewals} striped highlightOnHover pointerOnHover />
      <br />
    </Grid>
  );
};

export default ManageRenewals;
