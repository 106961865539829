import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import { AcademicYear } from '../../services/model';
import TextInput from '../../shared/components/form/TextInput';
import { axios } from '../../shared/singletons';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

type AcademicCardProps = {
  year?: AcademicYear;
  expanded?: boolean;
};

const dateFields = [
  ['start_date', 'Overall Start Date'],
  ['end_date', 'Overall End Date'],
  ['q1_sd', 'Q1 Start Date'],
  ['q1_ed', 'Q1 End Date'],
  ['q2_sd', 'Q2 Start Date'],
  ['q2_ed', 'Q2 End Date'],
  ['q3_sd', 'Q3 Start Date'],
  ['q3_ed', 'Q3 End Date'],
  ['q4_sd', 'Q4 Start Date'],
  ['q4_ed', 'Q4 End Date'],
  ['esy_sd', 'ESY Start Date'],
  ['esy_ed', 'ESY End Date'],
];

const AcademicYearCard: React.FC<AcademicCardProps> = (props) => {
  const { year, expanded } = props;

  const classes = useStyles();
  const history = useHistory();

  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    const obj = { title: year?.title, status: year?.status };
    dateFields.forEach((field) => {
      if (year) {
        obj[field[0]] = year[field[0]];
      } else {
        obj[field[0]] = '';
      }
    });

    setFormValues(obj);
  }, [year]);

  const handleFormValueChange = (field, value) => {
    const newFormValues = { ...formValues };
    newFormValues[field] = value;
    setFormValues(newFormValues);
  };

  const handleUpdate = async () => {
    setSubmitting(true);

    await axios
      .put(`payers/${year.payer_id}/academic_years/${year.id}.json`, {
        academic_year: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        setSubmitting(false);
        history.push(`/schools/${year.payer_id}/academic_years`);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
        setSubmitting(false);
      });
  };

  const handleCreate = async () => {
    setSubmitting(true);

    await axios
      .post(`payers/${year.payer_id}/academic_years`, {
        academic_year: formValues,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        setSubmitting(false);
        history.push(`/schools/${year.payer_id}/academic_years`);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
        setSubmitting(false);
      });
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary>
        <Typography variant="h2" style={{ textTransform: 'capitalize' }}>
          {year?.id ? `${year?.title} (${year?.status})` : 'Create New Academic Year'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify="space-between">
          <Grid></Grid>
          {dateFields.map((field) => (
            <Grid item xs={6}>
              <TextInput
                value={formValues[field[0]]}
                styleOverrides={classes.textInput}
                type="date"
                shrinkLabel
                errorMessage={formErrors[field[0]]}
                label={field[1]}
                valueChanged={(value) => {
                  handleFormValueChange(field[0], value);
                }}
              />
            </Grid>
          ))}

          {year?.id && (
            <>
              <br />
              <Grid item xs={12} md={8}>
                <InformationField label="Closure Dates" value={year?.formatted_closure_dates || 'None'} />
              </Grid>
              <Grid item xs={12} md={8} style={{ marginBottom: 20, marginTop: 10 }}>
                <InformationField label="Inservice Dates" value={year?.formatted_inservice_dates || 'None'} />
              </Grid>
              <br />
            </>
          )}
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name={'Yes'}
                  value={formValues.status}
                  color="primary"
                  onChange={() =>
                    setFormValues({ ...formValues, status: formValues.status === 'active' ? 'inactive' : 'active' })
                  }
                />
              }
              label="Make this the active year for this school"
              checked={formValues.status === 'active'}
            />
          </Grid>
          {year?.id ? (
            <Grid xs={12}>
              <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleUpdate}>
                {isSubmitting ? 'Submitting...' : 'Update Academic Year'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/schools/${year?.payer_id}/school_closings`)}
                style={{ marginLeft: 8 }}
              >
                Manage School Closings
              </Button>
            </Grid>
          ) : (
            <Grid xs={12}>
              <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleCreate}>
                {isSubmitting ? 'Submitting...' : 'Create Academic Year'}
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AcademicYearCard;
