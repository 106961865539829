import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StudentInformation from './StudentInformation';
import StudentContactInformation from './StudentContactInformation';
import { Student } from '../model';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import CommunicationsList from '../../communications/components/CommunicationsList';
import { UserContext } from '../../auth/contexts/userContext';
import { studentRecordStore } from '../../shared/singletons';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface StudentDetailsTabProps {
  student: Student;
  studentLoading: boolean;
}

const StudentDetailsTab: FunctionComponent<StudentDetailsTabProps> = (props) => {
  const { student, studentLoading } = props;
  const { currentUserHasAnyRole } = React.useContext(UserContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StudentInformation student={student} studentLoading={studentLoading} />
        </Grid>
        <Grid item xs={12} md={6}>
          <StudentContactInformation student={student} studentLoading={studentLoading} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AttachmentsList
            attachments={student?.attachments}
            showAddAttachment={student?.has_active_service}
            isLoading={studentLoading}
            attachmentableType="patients"
            attachmentableId={student?.id}
            afterAction={() => window.location.reload()}
          />
        </Grid>
        {currentUserHasAnyRole(['admin', 'service_provider', 'supervisor', 'services_coordinator']) && (
          <Grid item xs={12} md={6}>
            <CommunicationsList
              communications={student?.communications}
              parentType="patients"
              parentId={student?.id}
              isLoading={studentLoading}
              onSuccess={() => studentRecordStore.fetchRecord(student?.id)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default StudentDetailsTab;
