/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { UserTicketsContext } from '../contexts/UserTicketsContext';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { useStyles } from '../../shared/style/siteWideStyles';

const CompleteReferralModal = () => {
  const classes = useStyles();

  const { updateReferralTicket, referralTicketModalOpen, setReferralTicketModalOpen, referralToEdit } = useContext(
    UserTicketsContext,
  );

  const [formValues, setFormValues] = useState<any>();
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(false);

  useEffect(() => {
    setFormValues({
      status: 'approved',
      generate_connected: 'yes',
      start_date: referralToEdit?.start_date,
      end_date: referralToEdit?.end_date,
    });
  }, [referralToEdit]);

  useEffect(() => {
    setConfirmDisabled(formValues?.owner === undefined);
  }, [formValues]);

  const handleFormSubmit = () => {
    updateReferralTicket(referralToEdit, formValues);
  };

  return (
    <DeleteModal
      isOpen={referralTicketModalOpen}
      openStatusChanged={setReferralTicketModalOpen}
      onDelete={handleFormSubmit}
      height="800px"
      confirmLabel="Approve"
      confirmDisabled={confirmDisabled}
    >
      <>
        <Typography
          align="center"
          style={{
            fontSize: '20px',
            marginLeft: '20%',
            marginBottom: '20px',
            width: '60%',
          }}
          variant="h5"
        >
          Approve this referral?
        </Typography>
        <Grid container>
          <Grid item xs={5}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>Ticket Number</strong>: {referralToEdit?.id}
            </p>
          </Grid>
          <Grid item xs={5}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>Timeline ID</strong>: {referralToEdit?.student_slug}
            </p>
          </Grid>
          <Grid item xs={5}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>Service Type</strong>: Test Service
            </p>
          </Grid>
          <Grid item xs={5}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>School</strong>: {referralToEdit?.payer_name}
            </p>
          </Grid>
        </Grid>
        <br />
        <Grid container justify="center" style={{ marginTop: '10px' }}>
          <p style={{ width: '100%', margin: 0 }}>
            <span style={{ fontWeight: 'bold' }}>Service Category:</span> &ensp; {referralToEdit?.service_category}
          </p>
          <p style={{ width: '100%', margin: 0 }}>
            <span style={{ fontWeight: 'bold' }}>Primary Service:</span> &ensp; {referralToEdit?.service_name}
          </p>
          {referralToEdit?.service_category === 'Evaluation' ? (
            <Grid item xs={5}>
              <TextInput
                value={formValues?.start_date}
                styleOverrides={classes.selectInput}
                label="Initial Referral Date"
                type="date"
                shrinkLabel
                valueChanged={(value) => setFormValues({ ...formValues, start_date: value })}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={5}>
                <TextInput
                  value={formValues?.start_date}
                  styleOverrides={classes.selectInput}
                  label="Start Date"
                  type="date"
                  shrinkLabel
                  valueChanged={(value) => setFormValues({ ...formValues, start_date: value })}
                />
              </Grid>
              <Grid item xs={5}>
                <TextInput
                  value={formValues?.end_date}
                  styleOverrides={classes.selectInput}
                  label="End Date"
                  type="date"
                  shrinkLabel
                  valueChanged={(value) => setFormValues({ ...formValues, end_date: value })}
                />
              </Grid>
            </>
          )}
          <p style={{ width: '100%', margin: 0 }}>
            <span style={{ fontWeight: 'bold' }}>Connected Services:</span> &ensp;{' '}
            {referralToEdit?.connected_services?.join(', ')}
          </p>
          {referralToEdit?.service_category !== 'Screening' && (
            <Grid item xs={10}>
              <SelectInput
                value={formValues?.generate_connected || 'yes'}
                styleOverrides={classes.selectInput}
                label="Generate Connected Services"
                valueChanged={(value) => setFormValues({ ...formValues, generate_connected: value })}
                choices={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
              />
            </Grid>
          )}
          <Grid item xs={10}>
            <SelectInput
              value={formValues?.owner}
              styleOverrides={classes.selectInput}
              label="Provider"
              valueChanged={(value) => setFormValues({ ...formValues, owner: value })}
              choices={referralToEdit?.owner_choices}
              errorMessage={formValues?.owner === undefined ? 'must be selected' : ''}
            />
          </Grid>
          <Grid item xs={10}>
            <SelectInput
              value={formValues?.primary_service_stage}
              styleOverrides={classes.selectInput}
              label="Primary Service Stage"
              valueChanged={(value) => setFormValues({ ...formValues, primary_service_stage: value })}
              choices={referralToEdit?.referral_stage_choices}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: '20px' }}>
            <TextInput
              value={formValues?.admin_comment}
              styleOverrides={classes.textInput}
              multiline
              rows={2}
              label="Admin Comment"
              valueChanged={(value: string) => setFormValues({ ...formValues, admin_comment: value })}
            />
          </Grid>
        </Grid>
        <br />
      </>
    </DeleteModal>
  );
};

export default CompleteReferralModal;
