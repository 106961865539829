/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { TransferServicesFormValues, UserRouteParams } from '../model';
import SelectInput from '../../shared/components/form/SelectInput';
import { ServicesContext } from '../../services/contexts/ServicesContexts';
import { CaseLoadManagementContext } from '../contexts/CaseLoadManagementContext';
import Button, { ButtonFlavor } from '../../shared/components/Button';
import { SelectOption } from '../../shared/common.model';
import DataTable from '../../shared/components/DataTable';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '100%',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
    marginTop: '20px',
  },
});

interface AddOwnerToServicesForm {
  school_choices?: SelectOption[];
}

const AddOwnerToServicesForm: React.FunctionComponent<AddOwnerToServicesForm> = (props) => {
  const { school_choices } = props;

  const { userId } = useParams<UserRouteParams>();
  const classes = useStyles();

  const { activeOwnerChoices, fetchChoices } = useContext(ServicesContext);
  const { fetchActiveStudents, activeStudents, addOwnerToServices } = useContext(CaseLoadManagementContext);

  const [choicesFetched, setChoicesFetched] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [formValues, setFormValues] = useState<TransferServicesFormValues>({});
  const [studentIndex, setStudentIndex] = useState(0);
  const [studentId, setStudentId] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
      setFormValues({ school: '', owner: '' });
    }
  }, [fetchChoices, userId, choicesFetched, setChoicesFetched]);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const columns: any[] = [
    {
      name: 'Service Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Current Providers',
      selector: 'owner',
      sortable: true,
    },
    {
      name: 'Number of Appointments',
      selector: 'appointments_count',
      sortable: true,
    },
  ];

  const handleSchoolChange = (value) => {
    setSelectedSchool(value.toString());
    fetchActiveStudents(userId, value);
  };

  const handleAddOwners = () => {
    const serviceIds = selectedRows.map((service) => service.id);

    addOwnerToServices(userId, serviceIds, formValues.owner, () => {
      fetchActiveStudents(userId, selectedSchool);
      setDialogOpen(true);
    });
  };

  useEffect(() => {
    if (activeStudents?.length > 0) {
      setStudentId(activeStudents[0].id);
    }
  }, [activeStudents]);

  useEffect(() => {
    if (activeStudents?.length > 0) {
      const student = activeStudents.find((s) => s.id === studentId);
      setSelectedStudent(student);
      setStudentIndex(activeStudents.findIndex((s) => s.id === studentId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  const handleArrowBack = () => {
    let indexToSet = studentIndex - 1;
    if (indexToSet < 0) {
      indexToSet = activeStudents.length - 1;
    }
    setStudentId(activeStudents[indexToSet].id);
  };

  const handleArrowForward = () => {
    let indexToSet = studentIndex + 1;
    if (indexToSet > activeStudents.length - 1) {
      indexToSet = 0;
    }
    setStudentId(activeStudents[indexToSet].id);
  };

  return (
    <>
      {school_choices?.length === 0 && (
        <Card>
          <CardContent>
            <p>User has no active services.</p>
          </CardContent>
        </Card>
      )}
      {school_choices?.length > 0 && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h3" component="h3">
                Select School
              </Typography>
              <div style={{ marginLeft: -10 }}>
                <SelectInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="School"
                  errorMessage=""
                  value={selectedSchool}
                  valueChanged={(value: string) => handleSchoolChange(value)}
                  choices={school_choices}
                  defaultChoice={{ value: '', label: 'None' }}
                />
              </div>
            </Grid>
            {activeStudents?.length > 0 && (
              <Grid item xs={4}>
                <Typography variant="h3" component="h3">
                  Select Student
                </Typography>
                <div style={{ marginLeft: -10 }}>
                  <SelectInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Student"
                    errorMessage=""
                    value={studentId}
                    valueChanged={(value: string) => setStudentId(value)}
                    choices={activeStudents.map((student) => ({
                      label: `${student.slug} (${student.name})`,
                      value: student.id,
                    }))}
                    defaultChoice={{ value: '', label: 'None' }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <>
            {activeStudents?.length === 0 && selectedSchool && (
              <p style={{ color: 'red' }}>This provider has no remaining active students for this school.</p>
            )}
          </>
          <hr />
          <br />
          {selectedStudent && (
            <Grid container justify="space-between" alignItems="center" style={{ display: 'inline' }}>
              <Grid item xs={12}>
                <Grid container spacing={6} justify="center" style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosIcon onClick={() => handleArrowBack()} style={{ cursor: 'pointer' }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h2" component="h2">
                      {studentIndex + 1} of {activeStudents.length}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h2" component="h2">
                      {selectedStudent?.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h2" component="h2">
                      {selectedStudent?.slug}
                    </Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowForwardIosIcon onClick={() => handleArrowForward()} style={{ cursor: 'pointer' }} />
                  </Grid>
                </Grid>
                <Typography variant="h3" component="h3" style={{ marginTop: 20 }}>
                  Move Scheduled Appointments To New Owner
                </Typography>
                <Dialog
                  open={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      New owner has been added to service and appointments!
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      text="Review Changed Appointments"
                      disabled={false}
                      flavor={ButtonFlavor.Primary}
                      handleClick={() => window.open(`/students/${studentId}/appointments`, '_blank')}
                    />
                    <Button
                      text="Next Student"
                      disabled={false}
                      flavor={ButtonFlavor.Primary}
                      handleClick={() => setDialogOpen(false)}
                    />
                  </DialogActions>
                </Dialog>
                <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <CardContent>
                    <DataTable
                      title=""
                      columns={columns}
                      noHeader
                      data={selectedStudent?.services}
                      progressPending={false}
                      striped
                      highlightOnHover
                      pointerOnHover
                      pagination
                      selectableRows
                      selectableRowsHighlight
                      selectedRows={selectedRows}
                      onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                    />
                  </CardContent>
                </Card>
                <Grid container direction="column" style={{ marginTop: 20 }}>
                  <Grid item xs={4}>
                    <Typography variant="h3" component="h3">
                      Select New Owner
                    </Typography>
                    <div style={{ marginLeft: -10 }}>
                      <SelectInput
                        styleOverrides={classes.textInput}
                        type="text"
                        label="Provider"
                        errorMessage=""
                        value={formValues.owner}
                        valueChanged={(value: string) => handleFormValueChange('owner', value)}
                        choices={activeOwnerChoices}
                        defaultChoice={{ value: '', label: 'None' }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: '0' }}>
                    <Button
                      text="Add Provider"
                      disabled={false}
                      flavor={ButtonFlavor.Primary}
                      handleClick={() => handleAddOwners()}
                    />
                  </Grid>
                  <br />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default AddOwnerToServicesForm;
