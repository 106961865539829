import React, { FunctionComponent, useContext } from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { UserContext } from '../../auth/contexts/userContext';
import { serviceFormStore } from '../../shared/singletons';
import ServiceDetailsFormModal from './ServiceDetailsFormModal';
import ServiceStageChangeModal from './ServiceStageChangeModal';
import { Service } from '../model';
import ReactJoyride from 'react-joyride';
import { joyrideSteps } from '../../joyride/constants/joyrideSteps';
import { JoyrideContext } from '../../joyride/contexts/JoyrideContext';

interface ServiceInformationProps {
  service: Service;
  serviceLoading: boolean;
}

const ServiceInformation: FunctionComponent<ServiceInformationProps> = (props) => {
  const { service, serviceLoading } = props;
  const { user, currentUserHasRole } = useContext(UserContext);
  const { stepIndex, handleJoyrideCallback } = useContext(JoyrideContext);

  const handleEditClick = () => {
    serviceFormStore.setDetailsModalOpen(true, service);
  };

  const handleChangeStageClick = () => {
    serviceFormStore.setEditStageModalOpen(true, service);
  };

  return serviceLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      {user?.training_engaged?.joyride_tracking_name === 'referral_service_stage_change' && (
        <ReactJoyride
          steps={joyrideSteps}
          stepIndex={stepIndex}
          spotlightClicks={false}
          callback={(data) => handleJoyrideCallback(data)}
          run={user?.training_engaged?.joyride_tracking_name === 'referral_service_stage_change'}
          locale={{ close: 'OK' }}
        />
      )}
      <ServiceDetailsFormModal />
      <ServiceStageChangeModal />
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Service Information
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              {(currentUserHasRole('admin') || currentUserHasRole('service_provider')) && (
                <Grid item className="joyride-serviceStage-step1">
                  <Button variant="contained" color="primary" onClick={handleChangeStageClick}>
                    Change Stage
                  </Button>
                </Grid>
              )}
              {currentUserHasRole('admin') && (
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleEditClick} style={{ marginLeft: 7 }}>
                    Edit Service
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <InformationField
              label="Phase"
              value={service?.phase === 'patient' ? 'STUDENT' : service?.phase?.toUpperCase()}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Status" value={service?.status} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Stage" value={service?.stage} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Last Updated" value={service?.updated_at} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Provider" value={service?.owner} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Initial Referral Date" value={service?.initial_referral_date} />
          </Grid>
        </Grid>
        {service.connected_service && service.service_type !== 'Support for School Personnel' ? (
          <></>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <InformationField label="Frequency Description" value={service?.frequency} />
            </Grid>
            <Grid item xs={6} md={4}>
              <InformationField label="Start Date" value={service?.start_date} />
            </Grid>
            <Grid item xs={6} md={4}>
              <InformationField label="End Date" value={service?.end_date} />
            </Grid>
            {service?.compliance_tracking && currentUserHasRole('admin') && (
              <>
                <Grid item xs={6} md={4}>
                  <InformationField label="Compliance Tracking" value={service?.compliance_tracking} />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle1" component="h6">
                    Compliant by Schedule Date
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      color: '#F44F64',
                    }}
                  >
                    {service?.compliant_by_schedule_date}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle1" component="h6">
                    Compliant by Original Date
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      color: 'green',
                    }}
                  >
                    {service?.compliant_by_original_date}
                  </Typography>
                </Grid>
              </>
            )}
            {service?.compliance_error_message && (
              <Grid item xs={12}>
                <InformationField label="Compliance Error Message" value={service?.compliance_error_message} />
              </Grid>
            )}
            <Grid item xs={6} md={4}>
              <InformationField label="ESY Frequency" value={service?.esy_frequency} />
            </Grid>
            <Grid item xs={6} md={4}>
              <InformationField label="ESY Start Date" value={service?.esy_start_date} />
            </Grid>
            <Grid item xs={6} md={4}>
              <InformationField label="ESY End Date" value={service?.esy_end_date} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ServiceInformation;
