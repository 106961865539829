import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import EditReferralStageModal from './EditReferralStageModal';
import NewReferralStageModal from './NewReferralStageModal';
import ReferralStageRowMenu from './ReferralStageRowMenu';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import DataTable from '../../../shared/components/DataTable';
import { referralStageStore } from '../../../shared/singletons';
import { ReferralStage } from '../model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'GREEN',
    selector: 'green_range',
    sortable: true,
  },
  {
    name: 'YELLOW',
    selector: 'yellow_range',
    sortable: true,
  },
  {
    name: 'RED',
    selector: 'red_range',
    sortable: true,
  },
  {
    name: 'REFERRAL COUNT',
    selector: 'referrals_count',
    sortable: true,
  },
  {
    cell: (referralStage) => <ReferralStageRowMenu referralStage={referralStage} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

type ReferralStagesListProps = { records: ReferralStage[]; isLoading: boolean };

const ReferralStagesList: FunctionComponent<ReferralStagesListProps> = (props: ReferralStagesListProps) => {
  const { records = [], isLoading = false } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <EditReferralStageModal />
      <NewReferralStageModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Referral Stages
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => referralStageStore.setState({ createModalOpen: true })}
          >
            Create New Referral Stage +
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={records} striped highlightOnHover />
        </CardContent>
      </Card>
      <br />
    </Box>
  );
};

export default ReferralStagesList;
