import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import { userSelectOptionStore } from '../../shared/singletons';
import { useStyles } from '../../shared/style/siteWideStyles';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';

const UserEditDetailsForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formValues, setFormValues, user, formErrors } = useContext(InternalUsersContext);

  useEffect(() => {
    userSelectOptionStore.fetchChoices();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextInput
          styleOverrides={classes.textInput}
          label="First Name"
          errorMessage={formErrors?.first_name}
          value={formValues?.first_name}
          valueChanged={(value: string) => setFormValues({ ...formValues, first_name: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          styleOverrides={classes.textInput}
          label="Last Name"
          errorMessage={formErrors?.last_name}
          value={formValues?.last_name}
          valueChanged={(value: string) => setFormValues({ ...formValues, last_name: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          styleOverrides={classes.textInput}
          label="Email"
          errorMessage={formErrors?.email}
          value={formValues?.email}
          valueChanged={(value: string) => setFormValues({ ...formValues, email: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          styleOverrides={classes.textInput}
          label="Username"
          errorMessage={formErrors?.username}
          value={formValues?.username}
          valueChanged={(value: string) => setFormValues({ ...formValues, username: value })}
        />
      </Grid>
      <Grid item xs={12}>
        {user?.id == null && (
          <TextInput
            styleOverrides={classes.textInput}
            label="Password"
            errorMessage={formErrors?.password}
            value={formValues?.password}
            valueChanged={(value: string) => setFormValues({ ...formValues, password: value })}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default UserEditDetailsForm;
