/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, FunctionComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { Service } from '../../services/model';
import { School } from '../../schools/model';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { SelectOption } from '../../shared/common.model';
import { stringToDayOfWeek } from '../../shared/utils/date.utils';
import { useStyles } from '../../shared/style/siteWideStyles';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { AcademicYear } from '../../services/model';
import AppointmentErrorsList from './AppointmentErrorsList';

type YAGFormProps = {
  service?: Service;
  school?: School;
  locationId?: string;
  ownerId?: string;
  academicYear?: AcademicYear;
};

const minutesPerQuarterChoices = [
  { label: '45', value: '45' },
  { label: '75', value: '75' },
  { label: '90', value: '90' },
  { label: '135', value: '135' },
  { label: '255', value: '255' },
  { label: '465', value: '465' },
  { label: '675', value: '675' },
  { label: '885', value: '885' },
];

const appointmentsPerWeekChoices = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

const YAGForm: FunctionComponent<YAGFormProps> = (props) => {
  let { service, school, locationId, ownerId, academicYear } = props;
  let classes = useStyles();

  let [startDateLabel, setStartDateLabel] = useState('');
  let [disablePreviewButton, setDisablePreviewButton] = useState(true);
  let [countLabel, setCountLabel] = useState('Minutes Per Quarter');
  let [countChoices, setCountChoices] = useState(minutesPerQuarterChoices);
  let [ownerArray, setOwnerArray] = useState<string[]>([]);
  let [activeQuarterIndex, setActiveQuarterIndex] = useState(1);

  const {
    appointmentFormValues,
    setAppointmentFormValues,
    formShowing,
    setFormShowing,
    fetchAppointmentChoices,
    appointmentChoices,
    appointmentErrorWarnings,
  } = useContext(AppointmentsContext);

  const schoolHasAllDates = () => {
    const attributesToCheck = ['q1_sd', 'q2_sd', 'q3_sd', 'q4_sd', 'q1_ed', 'q2_ed', 'q3_ed', 'q4_ed'];
    return attributesToCheck.every((attr) => school?.[attr]);
  };

  // These default values should probably be established in the context when appointmentFormValues is set?

  const selectActiveQuarter = () => {
    if (school?.academic_years) {
      let activeYearObject = school.academic_years.find((year) => year.status === 'active');
      let today = new Date();
      [4, 3, 2, 1].forEach((quarter) => {
        let ed = new Date(activeYearObject?.[`q${quarter}_ed`]);
        if (today <= ed) {
          setActiveQuarterIndex(quarter);
          setAppointmentFormValues({ ...appointmentFormValues, schedule_date: activeYearObject?.[`q${quarter}_sd`] });
        }
      });
    }
  };

  useEffect(() => {
    const newFormValues = {
      ...appointmentFormValues,
      year_id: academicYear?.id,
      start_time: '00:00',
      frequency_count: '0',
      frequency_count_units: 'minutes',
      frequency_interval: 'quarter',
      type_of_schedule: 'minutes_per_quarter',
    };

    if (service?.id) {
      newFormValues.referral_service_id = service.id;
    }

    if (service?.frequency_count) {
      newFormValues.frequency_count = service.frequency_count;
      newFormValues.frequency_count_units = service.frequency_count_units;
      newFormValues.frequency_interval = service.frequency_interval;
    }

    if (locationId) {
      newFormValues.location_id = locationId;
    }

    if (ownerId) {
      newFormValues.owner_array = [ownerId.toString()];
    }

    setAppointmentFormValues(newFormValues);
    fetchAppointmentChoices(service?.student_id, service?.id);
    setStartDateLabel(`(${stringToDayOfWeek(`${school?.q1_sd} 12:00`)})`);
    selectActiveQuarter();
  }, [school, service, locationId, ownerId]);

  useEffect(() => {
    appointmentErrorWarnings(appointmentFormValues, 'admin', service);

    const formattedDate = new Date(appointmentFormValues?.schedule_date);
    let weekday = formattedDate.getDay();

    if (
      appointmentFormValues?.frequency_count !== '0' &&
      appointmentFormValues?.schedule_date &&
      weekday <= 4 &&
      appointmentFormValues?.start_time &&
      appointmentFormValues?.location_id &&
      appointmentFormValues?.owner_array?.length > 0 &&
      schoolHasAllDates()
    ) {
      setDisablePreviewButton(false);
    } else {
      setDisablePreviewButton(true);
    }
  }, [appointmentFormValues]);

  const setTargetType = (value: string) => {
    if (value === 'minutes_per_quarter') {
      setAppointmentFormValues({
        ...appointmentFormValues,
        type_of_schedule: value,
        frequency_count: '0',
        frequency_count_units: 'minutes',
        frequency_interval: 'quarter',
      });
      setCountLabel('Minutes Per Quarter');
      setCountChoices(minutesPerQuarterChoices);
    } else if (value === 'appointments_per_year') {
      setAppointmentFormValues({
        ...appointmentFormValues,
        type_of_schedule: value,
        frequency_count: '36',
        frequency_count_units: 'appointments',
        frequency_interval: 'year',
      });
    } else if (value === 'appointments_per_week') {
      setAppointmentFormValues({
        ...appointmentFormValues,
        type_of_schedule: value,
        frequency_count: '0',
        frequency_count_units: 'appointments',
        frequency_interval: 'week',
      });
      setCountLabel('Appointments Per Week');
      setCountChoices(appointmentsPerWeekChoices);
    }
  };

  const handleOwnerChecked = (value: string) => {
    let newOwnerArray = ownerArray;
    if (ownerArray.includes(value)) {
      newOwnerArray = ownerArray.filter((owner) => owner !== value);
    } else {
      newOwnerArray.push(value);
    }
    setOwnerArray(newOwnerArray);
    setAppointmentFormValues({ ...appointmentFormValues, owner_array: newOwnerArray });
  };

  return (
    <Grid container>
      {!schoolHasAllDates() && (
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            Warning
          </Typography>
          <p>
            {school?.name} is missing some quarter start dates or end dates. The appointment generator cannot work
            without them.
          </p>
        </Grid>
      )}
      <Grid item xs={6}>
        <SelectInput
          value={appointmentFormValues?.type_of_schedule}
          styleOverrides={classes.textInput}
          type="text"
          label="Type of Schedule"
          valueChanged={(value: string) => setTargetType(value)}
          choices={[
            { label: 'Minutes per Quarter', value: 'minutes_per_quarter' },
            { label: 'Appointments per Week', value: 'appointments_per_week' },
            // { label: 'Appointments per Year', value: 'appointments_per_year' },
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          value={appointmentFormValues?.frequency_count}
          styleOverrides={classes.textInput}
          type="text"
          label={countLabel}
          valueChanged={(value: string) =>
            setAppointmentFormValues({ ...appointmentFormValues, frequency_count: value })
          }
          choices={countChoices}
          defaultChoice={{ value: '0', label: '(Choose)' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          value={appointmentFormValues?.schedule_date}
          styleOverrides={classes.textInput}
          type="date"
          shrinkLabel
          label={`Start Date ${startDateLabel}`}
          valueChanged={(value: string) => {
            setAppointmentFormValues({ ...appointmentFormValues, schedule_date: value });
            setStartDateLabel(`(${stringToDayOfWeek(`${value} 12:00`)})`);
          }}
        />
        <p style={{ marginTop: '-10px' }}>
          This is the Q{activeQuarterIndex} start date for {school?.name}
        </p>
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          value={appointmentFormValues?.admin_units_override}
          styleOverrides={classes.textInput}
          type="text"
          label="Allow Service Provider to Exceed Maximum Units"
          valueChanged={(value: string) =>
            setAppointmentFormValues({ ...appointmentFormValues, admin_units_override: value })
          }
          choices={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          value={appointmentFormValues?.location_id}
          styleOverrides={classes.textInput}
          type="text"
          label="Location"
          valueChanged={(value: string) => setAppointmentFormValues({ ...appointmentFormValues, location_id: value })}
          choices={appointmentChoices?.locations}
          defaultChoice={{ value: '', label: 'None' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3">
          Provider
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <Grid container>
            {appointmentChoices?.owners?.map((owner: SelectOption) => (
              <Grid component={Box} key={owner.label} item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ownerSelection"
                      value={owner.value.toString()}
                      color="primary"
                      onChange={(event) => handleOwnerChecked(event.target.value)}
                      disabled={ownerId && ownerId.toString() !== owner.value.toString()}
                    />
                  }
                  label={owner.label}
                  checked={appointmentFormValues?.owner_array?.includes(owner.value.toString())}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <AppointmentErrorsList />
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setFormShowing(!formShowing)}
        disabled={disablePreviewButton}
      >
        Preview Calendar
      </Button>
    </Grid>
  );
};

export default YAGForm;
