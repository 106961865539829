import React, { useEffect, useContext } from 'react';
import { Student } from '../model';

import { today, oneYearFromNow } from '../../shared/utils/date.utils';

import AppointmentsListContainer from '../../appointments/components/AppointmentsListContainer';
import { FilterProps } from '../../shared/components/FilterBar';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { appointmentBaseStore } from '../../shared/singletons';
import { allAppointmentStatusChoices } from '../../appointments/constants';

import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
//
type StudentAppointmentsTabProps = {
  student: Student;
};

function StudentAppointmentsTab(props: StudentAppointmentsTabProps) {
  let { student } = props;

  let { fetchAppointments, setFilterValues, setDefaultFilterValues } = useContext(AppointmentsContext);

  let { allOwnerChoices } = useStoreObservable(appointmentBaseStore);

  const appointmentFilters: FilterProps[] = [
    {
      selector: 'ransack[patient_slug_cont]',
      label: 'Timeline ID',
      type: 'text',
      locked: true,
    },
    {
      selector: 'ransack[original_date_gteq]',
      label: 'Original Date From',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'ransack[original_date_lteq]',
      label: 'Original Date To',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'text',
      options: allAppointmentStatusChoices,
      advanced: false,
    },
    {
      selector: 'ransack[start_time_gteq]',
      label: 'Date From',
      type: 'date',
    },
    {
      selector: 'ransack[start_time_lteq]',
      label: 'Date To',
      type: 'date',
      advanced: false,
    },
    {
      selector: 'ransack[user_id_eq]',
      label: 'Provider',
      type: 'text',
      options: allOwnerChoices,
      advanced: true,
    },
    {
      selector: 'ransack[blackout_eq]',
      label: 'School Closing',
      type: 'text',
      options: [
        { label: 'Open', value: 'open' },
        { label: 'Conflict', value: 'black' },
      ],
      advanced: true,
    },
  ];

  let studentDefaultFilterValues = {
    'ransack[patient_slug_cont]': student.slug,
    'ransack[start_time_gteq]': today(),
    'ransack[start_time_lteq]': oneYearFromNow(),
  };

  useEffect(() => {
    setFilterValues(studentDefaultFilterValues);
    setDefaultFilterValues(studentDefaultFilterValues);
    fetchAppointments(studentDefaultFilterValues);
    appointmentBaseStore.fetchChoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student.slug]);

  return <AppointmentsListContainer filters={appointmentFilters} />;
}

export default StudentAppointmentsTab;
