import React, { FunctionComponent } from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import { User } from '../model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import UserFormModal from './UserFormModal';
import UserRoleFormModal from './UserRoleFormModal';
import UserPasswordModal from './UserPasswordModal';

import { UserContext } from '../../auth/contexts/userContext';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';

// TODO: Use the context instead of prop-drilling
interface UserInformationProps {
  user: User;
  userLoading: boolean;
}

const UserInformation: FunctionComponent<UserInformationProps> = (props) => {
  const { user, userLoading } = props;
  const { currentUserHasRole } = React.useContext(UserContext);
  const { setBasicInfoModalOpen, setRolesModalOpen, setPasswordModalOpen } = React.useContext(InternalUsersContext);

  return userLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <UserFormModal />
      <UserRoleFormModal />
      <UserPasswordModal />
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              User Information
            </Typography>
          </Grid>
          <Grid item>
            {currentUserHasRole('admin') && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setBasicInfoModalOpen(true)}
                  style={{ marginLeft: 7, marginRight: 7 }}
                >
                  Edit Details
                </Button>
                <Button variant="contained" color="primary" onClick={() => setRolesModalOpen(true)}>
                  Edit Role
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setPasswordModalOpen(true)}
                  style={{ marginLeft: 7 }}
                >
                  Reset Password
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={4} className="my-first-step">
            <InformationField label="Status" value={user?.status} />
          </Grid>
          <Grid item xs={6} md={4} className="my-second-step">
            <InformationField label="Name" value={user?.name} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Roles" value={user?.formatted_permission_role} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Contact Email" value={user?.email} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Supervisors" value={user?.supervisor_names} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Supervisors Emails" value={user?.supervisor_emails} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserInformation;
