import React, { FunctionComponent } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import { intervalChoices, frequencyCountUnitChoices } from '../../services/constants';

// import { stringToDayOfWeek } from '../../shared/utils/date.utils';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

const DuplicateAppointmentsForm: FunctionComponent = () => {
  const classes = useStyles();
  const { formValues, formErrors, setFormValues, appointmentScheduler } = React.useContext(
    DuplicateAppointmentsContext,
  );
  const { fetchChoices } = React.useContext(ServiceFormOptionsContext);

  // const [startDateLabel, setStartDateLabel] = React.useState('');

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  /* eslint-disable */
  React.useEffect(() => {
    fetchChoices();
  }, []);
  /* eslint-enable */

  return (
    <form noValidate autoComplete="off">
      <SelectInput
        value={formValues?.owner_id}
        styleOverrides={classes.textInput}
        type="text"
        label="New Provider"
        errorMessage={formErrors?.owner_id}
        valueChanged={(value: string) => handleFormValueChange('owner_id', value)}
        choices={appointmentScheduler.owner_choices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        value={formValues?.location_id}
        styleOverrides={classes.textInput}
        type="text"
        label="New Location"
        errorMessage={formErrors?.location_id}
        valueChanged={(value: string) => handleFormValueChange('location_id', value)}
        choices={appointmentScheduler.location_choices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <Grid item xs={12} style={{ marginLeft: 15 }}>
        <Typography>
          <strong>Frequency Description</strong>
        </Typography>
        {formValues.frequency}
      </Grid>
      <br />
      <Grid item xs={12}>
        <Typography style={{ marginLeft: 15 }}>
          <strong>Frequency</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Frequency Amount"
              value={formValues?.frequency_count}
              valueChanged={(value: string) => setFormValues({ ...formValues, frequency_count: value })}
            />
          </Grid>
          <Grid item xs={3} style={{ minWidth: 225 }}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Frequency Units"
              choices={frequencyCountUnitChoices}
              value={formValues?.frequency_count_units}
              valueChanged={(value: string) => setFormValues({ ...formValues, frequency_count_units: value })}
            />
          </Grid>
          <Grid item xs={1} style={{ margin: '30px 20px 0', alignItems: 'center' }}>
            <Typography style={{ textAlign: 'center' }}>Per</Typography>
          </Grid>
          <Grid item xs={3} style={{ minWidth: 225 }}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Frequency Interval"
              choices={intervalChoices}
              value={formValues?.frequency_interval}
              valueChanged={(value: string) => setFormValues({ ...formValues, frequency_interval: value })}
            />
          </Grid>
        </Grid>
      </Grid>
      <br />
    </form>
  );
};

export default DuplicateAppointmentsForm;
