/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import { RenewalOptionsContext } from '../../renewals/contexts/RenewalOptionsContext';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { SelectOption } from '../../shared/common.model';
import { axios } from '../../shared/singletons';
import InformationField from '../../shared/components/InformationField';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
}));

const RenewalForm: React.FC = (props) => {
  const { renewalStageChoices, renewalTypeChoices, ownerChoices } = React.useContext(RenewalOptionsContext);
  const {
    ownersExpanded,
    setOwnersExpanded,
    setNewRenewalCardShowing,
    setNewRenewalButtonShowing,
    setNewestRenewal,
    setContinueDisabled,
  } = React.useContext(AnnualUpdatesContext);
  const { student } = React.useContext(StudentsContext);

  const [disableCreate, setDisableCreate] = useState(true);
  const [serviceEndDate, setServiceEndDate] = useState('');

  const [formValues, setFormValues] = useState({
    referral_service_id: '',
    renewal_date: '',
    renewal_stage_id: '',
    active: '',
    renewal_type_id: '',
    user_ids: [],
    student_rr_date: student?.rr_date,
  });

  const classes = useStyles();

  useEffect(() => {
    if (formValues?.renewal_stage_id && formValues?.renewal_type_id) {
      setDisableCreate(false);
    } else {
      setContinueDisabled(true);
    }
  }, [formValues]);

  const handleUserChecks = (id) => {
    let newIds = formValues?.user_ids;

    if (formValues?.user_ids.includes(id)) {
      newIds = formValues.user_ids.filter((elem) => {
        return elem !== id;
      });
    } else {
      newIds.push(id);
    }

    setFormValues({ ...formValues, user_ids: newIds });
  };

  const createRenewal = () => {
    setOwnersExpanded(false);
    setNewRenewalCardShowing(false);
    setNewRenewalButtonShowing(false);
    axios.post(`patients/${student.id}/renewals`, { renewal: formValues }).then((response) => {
      setNewestRenewal(response?.data?.result);
    });
  };

  const renewalTypeLabel = renewalTypeChoices.find((choice) => choice.value === formValues.renewal_type_id)?.label;

  const handleServiceChange = (value) => {
    const chosenService = student?.service_options.find((service) => service.value === value);

    setFormValues({ ...formValues, referral_service_id: value });
    setServiceEndDate(chosenService?.end_date);
  };

  return (
    <Card>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              New Renewal
            </Typography>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setNewRenewalCardShowing(false);
              setNewRenewalButtonShowing(true);
            }}
            style={{ marginLeft: 7, backgroundColor: '#F44F64' }}
          >
            Cancel
          </Button>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              value={formValues?.referral_service_id}
              label="Service"
              choices={student?.service_options}
              valueChanged={handleServiceChange}
              type="text"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={3} md={3}>
            {renewalTypeLabel === 'RR' ? (
              <TextInput
                styleOverrides={classes.textInput}
                type="date"
                shrinkLabel
                label="Student RR Date"
                value={formValues?.student_rr_date}
                valueChanged={(value) => setFormValues({ ...formValues, student_rr_date: value })}
              />
            ) : (
              <InformationField label="Service End Date" value={serviceEndDate} />
            )}
          </Grid>

          <Grid item xs={3} md={3}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Renewal Stage"
              value={formValues?.renewal_stage_id}
              valueChanged={(value) => setFormValues({ ...formValues, renewal_stage_id: value })}
              choices={renewalStageChoices}
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Status"
              value={formValues?.active}
              valueChanged={(value: string) => setFormValues({ ...formValues, active: value })}
              choices={[
                { label: 'Active', value: 'true' },
                { label: 'Inactive', value: 'false' },
              ]}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Renewal Type"
              value={formValues.renewal_type_id}
              valueChanged={(value: string) => setFormValues({ ...formValues, renewal_type_id: value })}
              choices={renewalTypeChoices}
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
        </Grid>
        <br />

        <Grid container spacing={6}>
          <br />
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {ownersExpanded ? (
              <Button variant="contained" color="primary" onClick={() => setOwnersExpanded(false)}>
                Hide Provider Choices
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={() => setOwnersExpanded(true)}>
                Edit Providers
              </Button>
            )}
          </Grid>

          <Grid item xs={10}>
            <Typography variant="subtitle1" component="h6">
              Providers
            </Typography>

            {ownersExpanded ? (
              <FormGroup row>
                <Grid container>
                  {ownerChoices.map((owner: SelectOption) => (
                    <Grid key={owner.label} item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={owner.label}
                            value={owner.value}
                            color="primary"
                            onChange={() => handleUserChecks(owner.value)}
                          />
                        }
                        label={owner.label}
                        checked={formValues?.user_ids?.includes(owner?.value)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            ) : (
              ownerChoices

                .filter((owner) => {
                  return formValues?.user_ids?.includes(owner.value);
                })
                .map((owner, idx) => `${idx ? '; ' : ''} ${owner.label}`)
            )}
          </Grid>

          <Grid style={{ margin: 30, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" disabled={disableCreate} onClick={createRenewal}>
              Create
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RenewalForm;
