/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { Renewal } from '../model';
import RenewalFormModal from './RenewalFormModal';
import { renewalFormStore } from '../../shared/singletons';
import { RenewalsContext } from '../contexts/RenewalsContext';
import { RenewalOptionsContext } from '../contexts/RenewalOptionsContext';
import ContextFilterBar, { FilterProps } from '../../shared/components/ContextFilterBar';
import RenewalStageChangeModal from './RenewalStageChangeModal';
import { UserContext } from '../../auth/contexts/userContext';
import { rrColumns, iepColumns } from '../constants/renewalColumns';
import DownloadIEPRenewalsButton from './DownloadIEPRenewalsButton';
import DownloadRRRenewalsButton from './DownloadRRRenewalsButton';

// TODO: Update this to have an option to begin with a student filter
// Then we can replace the StudentRenewalsList and delete it

interface RenewalsCaseloadListProps {
  showDownloadButton?: boolean;
  showCreateButton?: boolean;
  studentId?: string;
  showFilterBar?: boolean;
  afterAction?: () => void;
}

const RenewalsCaseloadList: FunctionComponent<RenewalsCaseloadListProps> = (props) => {
  const {
    showDownloadButton = false,
    showCreateButton = false,
    studentId = null,
    showFilterBar = false,
    afterAction,
  } = props;

  const { currentUserHasRole, currentUserHasAnyRole } = React.useContext(UserContext);
  const {
    fetchIepRenewals,
    iepRenewals,
    totalIepRenewals,
    iepRenewalsLoading,

    fetchRevaluationRenewals,
    revaluationRenewals,
    totalRevaluationRenewals,
    revaluationRenewalsLoading,

    setFilterValues,

    filterValues,
    formattedFilterParams,

    iepDownloadError,
    rrDownloadError,
  } = React.useContext(RenewalsContext);
  const { fetchChoices, renewalStageChoices, renewalTypeChoices, allOwnerChoices, schoolChoices } = React.useContext(
    RenewalOptionsContext,
  );

  useEffect(() => {
    fetchChoices(studentId);
  }, [studentId]);

  const filters: FilterProps[] = [
    {
      selector: 'ransack[patient_slug_i_cont]',
      label: 'Timeline ID',
      type: 'text',
    },
    {
      selector: 'ransack[patient_last_name_i_cont]',
      label: 'Student Last Name',
      type: 'select',
    },
    {
      selector: 'ransack[patient_payer_id_eq]',
      label: 'School',
      type: 'text',
      options: schoolChoices,
    },
    {
      selector: 'ransack[ownerships_user_id_eq]',
      label: 'Provider',
      type: 'select',
      options: allOwnerChoices,
      advanced: true,
    },
    {
      selector: 'ransack[on_target_eq]',
      label: 'On Target',
      type: 'select',
      options: [
        { label: 'On Target', value: 'true' },
        { label: 'Off Target', value: 'false' },
      ],
    },
    {
      selector: 'ransack[renewal_stage_id_eq]',
      label: 'Renewal Stage',
      type: 'select',
      options: renewalStageChoices,
    },
    {
      selector: 'ransack[renewal_type_id_eq]',
      label: 'Renewal Type',
      type: 'select',
      options: renewalTypeChoices,
    },
    {
      selector: 'ransack[active_eq]',
      label: 'Active',
      type: 'select',
      options: [
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' },
      ],
      advanced: true,
    },
  ];

  const handleRevaluationsPageChange = (page: number) => {
    fetchRevaluationRenewals(null, page, 10);
  };

  const handleIepsPageChange = (page: number) => {
    fetchIepRenewals(null, page, 10);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      student_slug: 'patient_slug',
      school_abbreviation: 'patient_payer_abbr',
      student_name: 'patient_last_name',
      type: 'renewal_type_name',
      owner: 'users_last_name',
      formatted_renewal_date: 'renewal_date',
      therapist_input_date: 'renewal_date',
      iep_due_to_hope_date: 'renewal_date',
      input_approval_date: 'renewal_date',
      therapist_rr_input_date: 'renewal_date',
      rr_to_hope_date: 'renewal_date',
      rr_approval_date: 'renewal_date',
      timeline: 'renewal_date',
      stage: 'renewal_stage_name',
      status: 'on_target',
    };

    setFilterValues({
      ...filterValues,
      sort: `${columnNamesToRansack[column.selector]} ${sortDirection}`,
    });

    const params = formattedFilterParams({
      ...filterValues,
      sort: `${columnNamesToRansack[column.selector]} ${sortDirection}`,
    });

    fetchIepRenewals(params, 1, 10);
    fetchRevaluationRenewals(params, 1, 10);
  };

  let handleRowClicked = (renewal: Renewal) => {
    const newTabUrl = `/students/${renewal.student_id}/renewals/${renewal.id}`;
    window.open(newTabUrl, '_blank');
  };

  return (
    <Box>
      {currentUserHasRole('admin') && <RenewalFormModal onSuccess={afterAction} />}
      <RenewalStageChangeModal onSuccess={afterAction} />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Renewals
          </Typography>
        </Grid>
        <Grid item>
          {showCreateButton && currentUserHasRole('admin') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => renewalFormStore.setCreateModalOpen(true, studentId)}
            >
              Create New Renewal +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <ContextFilterBar
              filters={filters.filter((filter) => !filter.advanced)}
              advancedFilters={
                currentUserHasAnyRole(['admin', 'supervisor']) ? filters.filter((filter) => filter.advanced) : []
              }
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              onFilter={(params) => {
                fetchIepRenewals(params);
                fetchRevaluationRenewals(params);
              }}
            />
          </Grid>
        )}
      </Grid>
      <br />
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2" component="h2">
          IEP & 504 Renewals
        </Typography>
        {showDownloadButton && <DownloadIEPRenewalsButton />}
      </Grid>
      <Grid item xs={12}>
        {iepDownloadError && <Typography color="error">{iepDownloadError}</Typography>}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={iepColumns(currentUserHasRole('admin'))}
            noHeader
            data={iepRenewals}
            progressPending={iepRenewalsLoading}
            striped
            onRowClicked={(renewal: Renewal) => handleRowClicked(renewal)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalIepRenewals}
            onChangeRowsPerPage={() => null}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
            onChangePage={handleIepsPageChange}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
      <br />
      <hr />
      <br />
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2" component="h2">
          RR Renewals
        </Typography>
        {showDownloadButton && <DownloadRRRenewalsButton />}
      </Grid>
      <Grid item xs={12}>
        {rrDownloadError && <Typography color="error">{rrDownloadError}</Typography>}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={rrColumns(currentUserHasRole('admin'))}
            noHeader
            data={revaluationRenewals}
            progressPending={revaluationRenewalsLoading}
            striped
            onRowClicked={(renewal: Renewal) => handleRowClicked(renewal)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRevaluationRenewals}
            onChangeRowsPerPage={() => null}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
            onChangePage={handleRevaluationsPageChange}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RenewalsCaseloadList;
