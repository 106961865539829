import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SchoolInformation from './SchoolInformation';
import CurrentAcademicYearInformation from './CurrentAcademicYearInformation';
import { School } from '../model';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import SchoolDocuments from './SchoolDocuments';
import { schoolRecordStore } from '../../shared/singletons';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface SchoolDetailsTabProps {
  school: School;
  schoolLoading: boolean;
}

const SchoolDetailsTab: FunctionComponent<SchoolDetailsTabProps> = (props) => {
  const { school, schoolLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SchoolInformation school={school} schoolLoading={schoolLoading} />
          <br />
          <CurrentAcademicYearInformation school={school} schoolLoading={schoolLoading} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AttachmentsList
            title="Internal Attachments"
            description="These are internal Hope documents that are not viewable by the school users inside Timeline."
            attachments={school.attachments}
            showAddAttachment
            isLoading={schoolLoading}
            attachmentableType="payers"
            attachmentableId={school.id}
            afterAction={() => schoolRecordStore.fetchRecord(school.id)}
            schoolDocuments
          />
          <br />
          <SchoolDocuments
            attachments={school.school_documents}
            showAddAttachment
            isLoading={schoolLoading}
            attachmentableType="payers"
            attachmentableId={school.id}
            afterAction={() => schoolRecordStore.fetchRecord(school.id)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SchoolDetailsTab;
