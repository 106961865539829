/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import { RenewalOptionsContext } from '../../renewals/contexts/RenewalOptionsContext';
import { AnnualUpdatesContext } from '../contexts/AnnualUpdatesContext';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { SelectOption } from '../../shared/common.model';
import { axios } from '../../shared/singletons';
import { Renewal, RenewalFormValues } from '../../renewals/model';
import InformationField from '../../shared/components/InformationField';

type RenewalFormProps = {
  initialRenewal: Renewal;
  title: string;
};

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
}));

const RenewalForm: React.FC<RenewalFormProps> = (props) => {
  const { initialRenewal, title } = props;

  const { renewalStageChoices, renewalTypeChoices, ownerChoices } = React.useContext(RenewalOptionsContext);
  const { ownersExpanded, setOwnersExpanded, setContinueDisabled } = React.useContext(AnnualUpdatesContext);
  const { student } = React.useContext(StudentsContext);

  const [changesMade, setChangesMade] = useState(false);
  const [renewal, setRenewal] = useState(initialRenewal);
  const [formValues, setFormValues] = useState<RenewalFormValues>({});
  const [initialFormValues, setInitialFormValues] = useState<RenewalFormValues>({});

  const classes = useStyles();

  useEffect(() => {
    let formValuesObject = {
      id: renewal?.id,
      renewal_type_id: renewal?.renewal_type_id,
      student_rr_date: renewal?.student_rr_date,
      renewal_stage_id: renewal?.renewal_stage_id?.toString(),
      referral_service_id: renewal?.referral_service_id,
      active: renewal?.active,
      user_ids: renewal?.user_ids,
    };

    // Automatically set the renewal_stage_id to 1 and the student_rr_date
    let currentFormValuesObject = {
      ...formValuesObject,
      student_rr_date: renewal?.student_rr_date,
      renewal_stage_id: '1',
    };

    setInitialFormValues(formValuesObject);
    setFormValues(currentFormValuesObject);
  }, [renewal]);

  useEffect(() => {
    if (JSON.stringify(initialFormValues) === JSON.stringify(formValues)) {
      setContinueDisabled(false);
      setChangesMade(false);
    } else {
      setContinueDisabled(true);
      setChangesMade(true);
    }
  }, [formValues]);

  const handleUserChecks = (id) => {
    let newIds = formValues?.user_ids;

    if (formValues?.user_ids.includes(id)) {
      newIds = formValues.user_ids.filter((elem) => {
        return elem !== id;
      });
    } else {
      newIds.push(id);
    }

    setFormValues({ ...formValues, user_ids: newIds });
  };

  const updateRenewal = () => {
    setOwnersExpanded(false);
    axios.put(`patients/${student.id}/renewals/${initialRenewal.id}`, { renewal: formValues }).then((response) => {
      setRenewal(response?.data?.result);
    });
  };

  const renewalTypeLabel = renewalTypeChoices.find((choice) => choice.value === formValues.renewal_type_id)?.label;

  return (
    <Card>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={3} md={3}>
            {renewalTypeLabel === 'RR' ? (
              <TextInput
                styleOverrides={classes.textInput}
                type="date"
                shrinkLabel
                label="Student RR Date"
                value={formValues?.student_rr_date}
                valueChanged={(value) => setFormValues({ ...formValues, student_rr_date: value })}
              />
            ) : (
              <InformationField label="Service End Date" value={renewal?.service_end_date} />
            )}
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Renewal Stage"
              value={formValues?.renewal_stage_id}
              valueChanged={(value) => setFormValues({ ...formValues, renewal_stage_id: value })}
              choices={renewalStageChoices}
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Status"
              value={formValues?.active}
              valueChanged={(value: string) => setFormValues({ ...formValues, active: value })}
              choices={[
                { label: 'Active', value: 'true' },
                { label: 'Inactive', value: 'false' },
              ]}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Renewal Type"
              value={formValues.renewal_type_id}
              valueChanged={(value: string) => setFormValues({ ...formValues, renewal_type_id: value })}
              choices={renewalTypeChoices}
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
        </Grid>
        <br />

        <Grid container spacing={6}>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {ownersExpanded ? (
              <Button variant="contained" color="primary" onClick={() => setOwnersExpanded(false)}>
                Hide Provider Choices
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={() => setOwnersExpanded(true)}>
                Edit Providers
              </Button>
            )}
          </Grid>

          <Grid item xs={10}>
            <Typography variant="subtitle1" component="h6">
              Providers
            </Typography>

            {ownersExpanded ? (
              <FormGroup row>
                <Grid container>
                  {ownerChoices.map((owner: SelectOption) => (
                    <Grid key={owner.label} item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={owner.label}
                            value={owner.value}
                            color="primary"
                            onChange={() => handleUserChecks(owner.value)}
                          />
                        }
                        label={owner.label}
                        checked={formValues?.user_ids?.includes(owner?.value)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            ) : (
              ownerChoices
                .filter((owner) => {
                  return formValues?.user_ids?.includes(owner.value);
                })
                .map((owner, idx) => `${idx ? '; ' : ''} ${owner.label}`)
            )}
          </Grid>

          <Grid style={{ margin: 30, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" disabled={!changesMade} onClick={updateRenewal}>
              Update
            </Button>
            {changesMade && (
              <>
                &emsp;
                <p style={{ color: 'red' }}>Update to save your changes</p>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RenewalForm;
