import React, { FunctionComponent } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Button, FormGroup, FormControlLabel, Checkbox, Grid, Typography, makeStyles } from '@material-ui/core';
import SelectInput from '../../shared/components/form/SelectInput';
// import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
// import DataTable from '../../shared/components/DataTable';
import { reportStore, dashboardStore } from '../../shared/singletons';
import { SelectOption } from '../../shared/common.model';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const StudentBySchoolReport: FunctionComponent = () => {
  const classes = useStyles();

  const { schools } = useStoreObservable(dashboardStore);
  const { formValues, statusChoices, studentBySchoolRecords, isLoading } = useStoreObservable(reportStore);
  const [downloadDisabled, setDownloadDisabled] = React.useState(true);
  const [schoolsFetched, setSchoolsFetched] = React.useState(false);

  React.useEffect(() => {
    if (!schoolsFetched) {
      setSchoolsFetched(true);
      dashboardStore.fetchRecords();
    }
  }, [schoolsFetched]);

  const handleStatusChange = (key: string, value: string) => {
    setDownloadDisabled(true);
    reportStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  const handleSchoolsChange = (key: string, value: string) => {
    if (formValues[key] === undefined) {
      formValues[key] = [];
    }

    if (formValues[key].find((x: string) => x === value)) {
      formValues[key] = formValues[key].filter((p: string) => p !== value);
    } else {
      formValues[key] = [...formValues[key], value];
    }

    setDownloadDisabled(true);
    reportStore.setState({ formValues: { ...formValues, [key]: formValues[key] } });
  };

  const handleReportGeneration = async () => {
    setDownloadDisabled(true);
    await reportStore.generateStudentBySchoolReport();
    setDownloadDisabled(false);
  };

  return (
    <Box>
      <Typography variant="h2" component="h2">
        Select Status and Schools
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} xl={3}>
          <SelectInput
            styleOverrides={classes.textInput}
            type="text"
            label="Status"
            value={formValues?.status}
            valueChanged={(value: string) => handleStatusChange('status', value)}
            choices={statusChoices}
            defaultChoice={{ value: '', label: 'Select A Status' }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            Choose Schools
          </Typography>
        </Grid>
        <Grid item xs={8} md={6} xl={3}>
          <FormGroup row>
            <Grid container>
              {schools.map((school: SelectOption) => (
                <Grid key={school.label} item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={school.label}
                        value={school.value}
                        color="primary"
                        onChange={() => handleSchoolsChange('school_ids', school.value.toString())}
                      />
                    }
                    label={school.label}
                    checked={formValues?.school_ids?.includes(school?.value.toString())}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
      <br />
      <Button variant="contained" color="primary" onClick={handleReportGeneration}>
        {isLoading ? 'Generating...' : 'Generate Student By School Report'}
      </Button>
      <CSVLink
        data={studentBySchoolRecords}
        filename={`StudentBySchool_${formValues.school_ids}_STATUS_${formValues.status}.csv`}
        className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ${
          downloadDisabled ? 'Mui-disabled' : ''
        }`}
        style={{ marginLeft: '8px' }}
      >
        Download Report
      </CSVLink>
      {/* Commented out because a very long collection of records causes the page to crash */}
      {/* <Box my={4}>
        <DataTable title="" columns={columns} noHeader data={studentBySchoolRecords} />
      </Box> */}
    </Box>
  );
};

export default StudentBySchoolReport;
